import React, { useState, useRef, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { navigate } from "gatsby";
import { useDrag } from "react-use-gesture";
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image";

import useMedia from "@hooks/useMedia";
import { ScreenContext } from "@components/Layout";
import media from "@styles/media";
import colors from "@styles/colors";
import text from "@styles/text";

const PrimaryButton = loadable(() => import("@components/PrimaryButton"));
const ArrowSquare = loadable(() => import("@components/ArrowSquare"));
const TextAnimation = loadable(() => import("@components/TextAnimation"));
const Animation = loadable(() => import("@components/Animation"));

const Causes: React.FC = () => {
  const [enter1, setEnter1] = useState(false);
  const [enter2, setEnter2] = useState(false);
  const [index, setIndex] = useState(0);
  const [triggerTitle1, setTriggerTitle1] = useState(false);
  const [triggerText1, setTriggerText1] = useState(false);
  const [triggerTitle2, setTriggerTitle2] = useState(false);

  const wrapperRef = useRef(null);
  const tileContainerRef = useRef(null);
  const imgRef = useRef(null);
  const content2Ref = useRef(null);
  const buttonContainerRef = useRef(null);
  const tile1Ref = useRef(null);
  const tile2Ref = useRef(null);
  const tile3Ref = useRef(null);
  const tile4Ref = useRef(null);
  const tile5Ref = useRef(null);
  const screen = useContext(ScreenContext);

  let start1 = useMedia("top-=300 top", "top-=300 top", "top-=60% top", "");
  let start2 = useMedia("top-=200 top", "top-=200 top", "top-=100% top", "");

  useEffect(() => {

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: start1,
        },
      });
      tl.to(imgRef.current, {
        duration: 1,
        height: "100%",
        ease: "circ.inOut",
      });
      tl.call(setTriggerTitle1, [true], 0.8);
      tl.call(setTriggerText1, [true], 1.2);

      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: content2Ref.current,
          start: start2,
        },
      });

      tl2.to(buttonContainerRef.current, {
        opacity: 1,
        duration: 1,
      });
      tl2.call(setTriggerTitle2, [true], 0);
      tl2.to(
        tile1Ref.current,
        {
          duration: 1,
          opacity: 1,
        },
        1
      );
      tl2.to(
        tile2Ref.current,
        {
          duration: 1,
          opacity: 1,
        },
        1.2
      );
      tl2.to(
        tile3Ref.current,
        {
          duration: 1,
          opacity: 1,
        },
        1.4
      );
      tl2.to(
        tile4Ref.current,
        {
          duration: 1,
          opacity: 1,
        },
        1.6
      );
      tl2.to(
        tile5Ref.current,
        {
          duration: 1,
          opacity: 1,
        },
        1.8
      );

      const tl3 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top-=200 top",
          scrub: true,
        },
      });
      tl3.to(imgRef.current, {
        scale: "1.5",
      });

      return () => {
        tl.kill();
        tl2.kill();
        tl3.kill();
      };

  }, []);

  let value = useMedia("-25.834", "-25.834", "-39.568", "-86.666");
  let cutOff = useMedia(2, 2, 3, 4);

  const handleNext = () => {
    let newIndex = index;

    if (screen.desktop || screen.fullWidth) {
      newIndex = cutOff
    }
    else if (index < cutOff) {
      newIndex = index + 1;
    }

    setIndex(newIndex);
    let left = newIndex * value;
    let leftString = `${left}vw`;

    gsap.to(tileContainerRef.current, {
      duration: 1,
      left: leftString,
    });
    
  }

  const handlePrev = () => {
    let newIndex = index

    if (screen.desktop || screen.fullWidth) {
      newIndex = 0
    }
    else if (index > 0) {
      newIndex = index - 1;
    }

    setIndex(newIndex);
    let left = newIndex * value;
    let leftString = `${left}vw`;

    gsap.to(tileContainerRef.current, {
      duration: 1,
      left: leftString,
    });
    
  }

  const bind = useDrag(
    (state) => {
      if (!screen.mobile) {
        if (state.direction[0] < 0) {
          handleNext();
        }
        if (state.direction[0] > 0) {
          handlePrev();
        }
      }
    },
    { threshold: useMedia(10, 10, 50, 8) }
  );

  const title2TextArray = useMedia(
    [
      () => "mIQroAware™ monitors the",
      () => "leading causes of pipeline",
      () => "leaks and more:",
    ],
    [
      () => "mIQroAware™ monitors the",
      () => "leading causes of pipeline",
      () => "leaks and more:",
    ],
    [
      () => "mIQroAware™ monitors the",
      () => "leading causes of pipeline",
      () => "leaks and more:",
    ],
    [
      () => "mIQroAware™",
      () => "monitors the leading",
      () => "causes of pipeline",
      () => "leaks and more",
    ]
  );

  return (
    <Wrapper ref={wrapperRef}>
      <Content1>
        <Title1>
          <TextAnimation
            textArray={[
              () => "Redefining pipeline",
              () => "monitoring with",
              () => "mIQroAware\u2122",
            ]}
            className="causes-title-1"
            height={useMedia("4.167vw", "4.167vw", "5.755vw", "9.600vw")}
            trigger={triggerTitle1}
          />
        </Title1>
        <Text>
          <TextAnimation
            textArray={[
              () =>
                "The mIQroAware™ solution offers a full suite of integrated technologies working together to provide you with instant, intelligent insights. By blending sensor, Internet of Things (IoT), Artificial Intelligence (AI), and Analytics technology, this innovative system monitors the 7 most important parameters of pipeline health at a rate of up to 42 times per second. ",
            ]}
            className="causes-text-1"
            height={useMedia("13.333vw", "13.333vw", "24.103vw", "37.333vw")}
            trigger={triggerText1}
          />
        </Text>
        <PrimaryButton
          backgroundColor={colors.black}
          textColor={colors.white}
          text="Learn more"
          width={useMedia("fit-content", "fit-content", "fit-content", "100%")}
          onClick={() => navigate("/miqroaware")}
        />
      </Content1>
      <Gradient1 />
      <ImgWrapper>
        <Img ref={imgRef}>
          <StaticImage
            src={"../../images/jpg/causes.jpg"}
            alt="causes"
            placeholder="blurred"
            objectFit="cover"
            style={{width: '100%', height: '100%'}}
          />
        </Img>
      </ImgWrapper>
      <AnimationWrapper>
        <Gradient2 />
        <Animation />
      </AnimationWrapper>
      <Content2 ref={content2Ref}>
        <Title2>
          <TextAnimation
            textArray={title2TextArray || []}
            className="causes-title-2"
            height={useMedia("4.861vw", "4.861vw", "5.755vw", "9.600vw")}
            trigger={triggerTitle2}
          />
        </Title2>
        <ButtonContainer ref={buttonContainerRef}>
          <ArrowContainer1
            onClick={handlePrev}
            onMouseEnter={() => setEnter1(true)}
            onMouseLeave={() => setEnter1(false)}
          >
            <ArrowSquare
              mouseEnter={enter1}
              color={index > 0 ? colors.black : "rgba(5, 5, 5, 0.3)"}
            />
          </ArrowContainer1>
          <ArrowContainer2
            onClick={handleNext}
            onMouseEnter={() => setEnter2(true)}
            onMouseLeave={() => setEnter2(false)}
          >
            <ArrowSquare
              mouseEnter={enter2}
              color={index < cutOff ? colors.black : "rgba(5, 5, 5, 0.3)"}
            />
          </ArrowContainer2>
        </ButtonContainer>
        <TileContainer ref={tileContainerRef} {...bind()}>
          <TileWrapper ref={tile1Ref}>
            <TileImg>
              <StaticImage
                src={"../../images/jpg/corrosion.jpg"}
                alt="corrosion"
                placeholder="blurred"
                style={{width: '100%', height: '100%'}}
              />
            </TileImg>
            <TileSpan>
              <TileNumber>01</TileNumber>
              <TileTitle>Corrosion</TileTitle>
            </TileSpan>
            <TileText>
              Our patented technology monitors for internal and external
              corrosion using state of the art sensors. These medical-grade
              sensors change the way corrosion monitoring is done. This analysis
              is so accurate, it can determine the rate of corroding down to
              0.2mm of loss annually— less than the thickness of two human
              hairs.
            </TileText>
          </TileWrapper>
          <TileWrapper ref={tile2Ref}>
            <TileImg>
              <StaticImage
                src={"../../images/jpg/human_error.jpg"}
                alt="human error"
                placeholder="blurred"
                style={{width: '100%', height: '100%'}}
              />
            </TileImg>
            <TileSpan>
              <TileNumber>02</TileNumber>
              <TileTitle>Human error</TileTitle>
            </TileSpan>
            <TileText>
              Whether it is an overpressure event, overheat, wellhead issue,
              non-authorized dig, or even sabotage, our devices measure a
              variety of variables to detect the threat to your asset. Whether
              the error is due to operational mishaps or illegal activity,
              you’ll have a clear picture of what’s going on.
            </TileText>
          </TileWrapper>
          <TileWrapper ref={tile3Ref}>
            <TileImg>
              <StaticImage
                src={"../../images/jpg/component_failure.jpg"}
                alt="component failure"
                placeholder="blurred"
                style={{width: '100%', height: '100%'}}
              />
            </TileImg>
            <TileSpan>
              <TileNumber>03</TileNumber>
              <TileTitle>Component failure</TileTitle>
            </TileSpan>
            <TileText>
              There are a variety of reasons that pipeline components such as
              valves or compressor stations may fail. Weld failure, human error,
              and even acoustic shock can all factor into this failure. We
              collect data up to 42 times per second to predict these failures
              before they occur.
            </TileText>
          </TileWrapper>
          <TileWrapper ref={tile4Ref}>
            <TileImg>
              <StaticImage
                src={"../../images/jpg/earth_movement.jpg"}
                alt="earth movement"
                placeholder="blurred"
                style={{width: '100%', height: '100%'}}
              />
            </TileImg>
            <TileSpan>
              <TileNumber>04</TileNumber>
              <TileTitle>Earth movement</TileTitle>
            </TileSpan>
            <TileText>
              Earthquakes, land slippage, land slides, and ground settling
              damage pose a real threat to pipeline infrastructure. Our instant
              alerts allow mitigation of these risks to reduce damage and avoid
              disaster.
            </TileText>
          </TileWrapper>
          <TileWrapper ref={tile5Ref}>
            <TileImg>
              <StaticImage
                src={"../../images/jpg/safety_system_failure.jpg"}
                alt="system safety failure"
                placeholder="blurred"
                style={{width: '100%', height: '100%'}}
              />
            </TileImg>
            <TileSpan>
              <TileNumber>05</TileNumber>
              <TileTitle>Safety system failure</TileTitle>
            </TileSpan>
            <TileText>
              We help keep your safety systems in check and are working toward
              making pigging obsolete. PHMSA data shows that hot-tapping and
              pigging are responsible for over 2% of pipeline leaks. By
              monitoring externally, we prevent these leaks and even monitor for
              lost pigs and leaks from competitors.
            </TileText>
          </TileWrapper>
        </TileContainer>
      </Content2>
    </Wrapper>
  );
};

export default Causes;

const Wrapper = styled.section`
  width: 100%;
  background-color: ${colors.culturedWhite40};
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${media.fullWidth} {
    padding-left: 11.389vw;
    padding-top: 13.472vw;
    padding-bottom: 11.389vw;
  }

  ${media.desktop} {
    padding-left: 11.389vw;
    padding-top: 13.472vw;
    padding-bottom: 11.389vw;
  }

  ${media.tablet} {
    padding-left: 5.995vw;
    padding-top: 13.669vw;
    padding-bottom: 13.549vw;
  }

  ${media.mobile} {
    padding-left: 6.667vw;
    padding-top: 6.667vw;
    padding-bottom: 26.667vw;
    height: 500vw;
  }
`;

const Title1 = styled.h2`
  color: ${colors.black};
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    ${text.desktopMHeading}
    margin-bottom: 2.778vw;
    width: 36.111vw;
  }

  ${media.desktop} {
    ${text.desktopMHeading}
    margin-bottom: 2.778vw;
    width: 36.111vw;
  }

  ${media.tablet} {
    ${text.tabletSHeading}
    margin-bottom: 3.597vw;
    width: 50.763vw;
  }

  ${media.mobile} {
    ${text.mobileXSHeading}
    margin-bottom: 5.333vw;
    width: 86.667vw;
  }
`;

const Title2 = styled.h2`
  color: ${colors.black};
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;

  ${text.desktopLHeading}
  margin-top: 34.722vw;
  margin-bottom: 6.25vw;
  width: 60.028vw;

  ${media.tablet} {
    ${text.tabletSHeading}
    margin-top: 23.621vw;
    margin-bottom: 4.916vw;
    width: 72.787vw;
  }

  ${media.mobile} {
    ${text.mobileXSHeading}
    margin-top: 0vw;
    margin-bottom: 13.333vw;
    width: 86.667vw;
  }
`;

const Text = styled.p`
  color: ${colors.black};
  display: flex;

  ${media.fullWidth} {
    ${text.desktopBodyCopy1}
    width: 25.694vw;
    margin-bottom: 2.778vw;
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1}
    width: 25.694vw;
    margin-bottom: 2.778vw;
  }

  ${media.tablet} {
    ${text.tabletBodyCopy1}
    width: 44.365vw;
    margin-bottom: 4.796vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    width: 86.667vw;
    margin-bottom: 8vw;
  }
`;

const AnimationWrapper = styled.div`
  position: absolute;
  z-index: 0;
  width: 100vw;
  left: 0;

  ${media.fullWidth} {
    top: 44.792vw;
    height: 53.681vw;
  }

  ${media.desktop} {
    top: 44.792vw;
    height: 53.681vw;
  }

  ${media.tablet} {
    top: 66.792vw;
    height: 53.681vw;
  }
`;

const Gradient1 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    ${colors.culturedWhite100} 32.77%,
    rgba(248, 248, 248, 0) 95.59%
  );

  width: 35.833vw;
  height: 45.278vw;

  ${media.tablet} {
    width: 34.173vw;
    height: 53.357vw;
  }

  ${media.mobile} {
    width: 70.4vw;
    height: 118.667vw;
  }
`;

const Gradient2 = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    ${colors.culturedWhite40},
    rgba(224, 224, 224, 0.8),
    ${colors.culturedWhite40}
  );
  height: 100%;
`;

const Content1 = styled.div`
  position: relative;
  z-index: 5;

  ${media.mobile} {
    position: absolute;
    top: 143.2vw;
    left: 6.667vw;
  }
`;

const Content2 = styled.div`
  position: relative;
  z-index: 5;

  ${media.mobile} {
    position: absolute;
    top: 275vw;
    left: 6.667vw;
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${media.fullWidth} {
    width: 42.986vw;
    height: 688px;
    right: 3.611vw;
    top: 7.431vw;
  }

  ${media.desktop} {
    width: 42.986vw;
    height: 47.778vw;
    right: 3.611vw;
    top: 7.431vw;
  }

  ${media.tablet} {
    width: 43.165vw;
    height: 52.758vw;
    right: 0vw;
    top: 13.429vw;
  }

  ${media.mobile} {
    width: 86.667vw;
    height: 105.867vw;
    right: 6.667vw;
    top: 29.333vw;
  }
`;

const Img = styled.div`
  width: 100%;
  height: 0%;
  object-fit: cover;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  opacity: 0;
  width: 6.9vw;
  right: 3.5vw;
  bottom: 38vw;

  ${media.tablet} {
    width: 11.871vw;
    right: 5.995vw;
    bottom: 62.659vw;
  }

  ${media.mobile} {
    display: none;
  }
`;

const ArrowContainerStyles = `

  ${media.fullWidth} {
    width: 2.8vw;
    height: 2.8vw;
  }

  ${media.desktop} {
    width: 2.8vw;
    height: 2.8vw;
  }

  ${media.tablet} {
    width: 4.8vw;
    height: 4.8vw;
  }
`;

const ArrowContainer1 = styled.div`
  ${ArrowContainerStyles}
  transform: rotate(180deg);
`;

const ArrowContainer2 = styled.div`
  ${ArrowContainerStyles}
`;

const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;

  ${media.fullWidth} {
    width: 21.667vw;
    margin-right: 4.167vw;
  }

  ${media.desktop} {
    width: 21.667vw;
    margin-right: 4.167vw;
  }

  ${media.tablet} {
    width: 33.573vw;
    margin-right: 5.995vw;
  }

  ${media.mobile} {
    width: 74.933vw;
    margin-right: 11.733vw;
  }
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  ${media.mobile} {
    width: 93.333vw;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }

    ${TileWrapper}:nth-child(5) {
      padding-right: 8vw;
    }
  }
`;

const TileImg = styled.div`
  object-fit: cover;
  object-position: 50% 50%;
  width: 21.7vw;
  height: 13.3vw;
  margin-bottom: 2.083vw;

  ${media.tablet} {
    width: 33.573vw;
    height: 25.06vw;
    margin-bottom: 3.597vw;
  }

  ${media.mobile} {
    width: 74.933vw;
    height: 55.733vw;
    margin-bottom: 8vw;
  }
`;

const TileSpan = styled.span`
  width: 100%;
  border-top: 1px solid ${colors.jetBlack100};
  border-bottom: 1px solid ${colors.jetBlack100};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.fullWidth} {
    height: 3.333vw;
    margin-bottom: 1.389vw;
  }

  ${media.desktop} {
    height: 3.333vw;
    margin-bottom: 1.389vw;
  }

  ${media.tablet} {
    height: 5.755vw;
    margin-bottom: 2.398vw;
  }

  ${media.mobile} {
    height: 13.333vw;
    margin-bottom: 5.333vw;
  }
`;

const TileNumber = styled.span`
  background-color: ${colors.black};
  color: ${colors.culturedWhite60};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  letter-spacing: -0.04em;

  ${media.fullWidth} {
    width: 2.083vw;
    height: 1.944vw;
    font-size: 1.25vw;
  }

  ${media.desktop} {
    width: 2.083vw;
    height: 1.944vw;
    font-size: 1.25vw;
  }

  ${media.tablet} {
    width: 3.597vw;
    height: 3.357vw;
    font-size: 2.158vw;
  }

  ${media.mobile} {
    width: 8vw;
    height: 7.467vw;
    font-size: 4.8vw;
  }
`;

const TileTitle = styled.h3`
  color: ${colors.black};

  ${text.desktopXXSHeading}
  font-family: Helvetica Neue;

  ${media.tablet} {
    ${text.tabletPetiteHeading}
    font-family: Helvetica Neue;
  }

  ${media.mobile} {
    ${text.mobilePetiteHeading}
    font-family: Helvetica Neue;
  }
`;

const TileText = styled.p`
  color: ${colors.black};

  ${media.fullWidth} {
    ${text.desktopBodyCopy1}
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1}
  }

  ${media.tablet} {
    ${text.tabletSmallBody}
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
  }
`;
